import React from'react'
import "../styles/raid.css"

class RAID extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            diskCount: 2,
            diskSize: 1,
            raidType: "RAID0",
            useable: '',
            performance: "",
            tolerance: "",
            message: null,
            utilization: [],
            utilizationHeader: ['RAID0','RAID1','RAID10','RAID5','RAID6']
        }
    }

    componentDidMount = () => {
        this.calculateRaid(this.state.diskCount, this.state.diskSize, this.state.raidType)
    }

    updateDiskCount = (e) => {
        const diskCount = e.target.value
        if(diskCount < 0 ){return}
        this.calculateRaid(diskCount,this.state.diskSize,this.state.raidType)
    }
    
    updateDiskSize = (e) => {
        const diskSize = e.target.value
        if(diskSize < 0 ){return}
        this.calculateRaid(this.state.diskCount,diskSize,this.state.raidType)
    }
    
    updateRaidType = (e) => {
        const raidType = e.target.value
        if(raidType < 0 ){return}
        this.calculateRaid(this.state.diskCount,this.state.diskSize,raidType)
    }

    calculateRaid(diskCount,diskSize,raidType){
        let useable, performance, tolerance
        let message = null
        switch(raidType){
            case 'RAID1':
                if(diskCount < 2){
                    useable = "-"
                    performance = "-"
                    tolerance = "-"
                    message = "RAID1 needs a minimum of 2 drives"
                }else{
                    useable = diskSize + 'TB ('+ Math.round((diskSize / (diskSize*diskCount))*100) +'%)'
                    performance = diskCount + "x read and 1x write"
                    tolerance = (diskCount - 1) + " Disk"
                    if(diskCount > 2){
                        message = "RAID1 is not efficient for more than 2 disks."
                    }
                }
                break;
            case 'RAID5':
                if(diskCount < 3){
                    useable="-"
                    performance="-"
                    tolerance="-"
                    message="RAID5 needs a minimum of 3 drives"
                }else{
                    useable = diskCount * diskSize - diskSize + 'TB ('+ Math.round(((diskCount * diskSize - diskSize) / (diskSize*diskCount))*100) +'%)'
                    performance = (diskCount-1) + "x read and 1x write"
                    tolerance = "1 Disk"
                }
                break;    
            case 'RAID6':
                if(diskCount < 4){
                    useable="-"
                    performance="-"
                    tolerance="-"
                    message="RAID6 needs a minimum of 4 drives"
                }else{
                    useable = (diskCount - 2) * diskSize + 'TB ('+ Math.round((((diskCount - 2) * diskSize) / (diskSize*diskCount))*100) +'%)'
                    performance = (diskCount-2) + "x read and 1x write"
                    tolerance = "2 Disk"
                }
                break;    
            case 'RAID10':
                if(diskCount < 4){
                    useable="-"
                    performance="-"
                    tolerance="-"
                    message="RAID10 needs a minimum of 4 drives"
                }else if(diskCount % 2 !== 0){
                        useable="-"
                        performance="-"
                        tolerance="-"
                        message="RAID10 needs an even number of drives"
                    }else{
                        useable = diskCount * diskSize / 2 + 'TB ('+ Math.round(((diskCount * diskSize / 2) / (diskSize*diskCount))*100) +'%)'
                        performance = diskCount + "x read and " + diskCount/2 + "x write"
                        tolerance = "Atleast 1 Disk"
                    }
                break
            default:
                if(diskCount < 2){
                    useable="-"
                    performance="-"
                    tolerance="-"
                    message="RAID0 needs a minimum of 2 drives"
                }else{
                    useable = diskCount * diskSize + 'TB (100%)'
                    performance = diskCount + "x read and write"
                    tolerance = "None"
                }
                break
        }

        let utilization = []
        //RAID0
        if(diskCount < 2 ){
            utilization[0] = '-'
        }else{
            utilization[0] = diskCount * diskSize
        }
        //RAID1
        if(diskCount >=2 ){
        utilization[1] = diskSize
        }else{
            utilization[1] = '-'
        }
        //RAID10
        if(diskCount >=4 && diskCount % 2 ===0 ){
            utilization[2] = diskCount * diskSize / 2
        }else{
            utilization[2] = '-'
        }
        //RAID5
        if(diskCount >= 3){
            utilization[3] = diskCount * diskSize - diskSize
        }else{
            utilization[3] = '-'
        }
        //RAID6
        if(diskCount >= 4){
            utilization[4] = (diskCount - 2) * diskSize
        }else{
            utilization[4] = '-'

        }

        this.setState({diskCount,diskSize,raidType,useable,performance,tolerance,message,utilization})
    }

    render(){
        return(
            <div id="raidWrapper">
                <h1>RAID Calculator</h1>
                <div id="inputWrapper" className="container border-bottom pb-4">
                    <div className="row">
                        <div className="col">
                            <h2>Build Options</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col input-group mb-3">
                            <span className="input-group-text">Disks in Array</span>
                            <input type="number" className="form-control" onChange={this.updateDiskCount} value={this.state.diskCount} />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col input-group mb-3">
                            <span className="input-group-text">Single Disk Size</span>
                            <input type="number" className="form-control" onChange={this.updateDiskSize} value={this.state.diskSize} />
                            <span className="input-group-text">TB</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col input-group">
                            <span className="input-group-text">RAID Config</span>
                            <select className="form-select" value={this.state.raidType} onChange={this.updateRaidType}>
                                <option value="RAID0">RAID0 Stripped</option>
                                <option value="RAID1">RAID1 Mirror</option>
                                <option value="RAID10">RAID10 Stripped Mirror</option>
                                <option value="RAID5">RAID5 Parity</option>
                                <option value="RAID6">RAID6 Double Parity</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div id="outputWrapper" className="container mt-4 border-bottom pb-4">
                    <div className="row">
                        <div className="col">
                            <h2>{this.state.raidType} Information</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">Useable Space</div>
                        <div className="col">{this.state.useable}</div>
                    </div>
                    <div className="row">
                        <div className="col">Performance</div>
                        <div className="col">{this.state.performance}</div>
                    </div>
                    <div className="row">
                        <div className="col">Tolerance</div>
                        <div className="col">{this.state.tolerance}</div>
                    </div>
                    {(this.state.message !== null ?
                        <div className="row">
                            <div className="col fw-bold text-danger">{this.state.message}</div>
                        </div>
                        :<></>
                    )}
                </div>
                <div id="outputWrapper" className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h2>Utilization Comparison</h2>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.utilizationHeader.map((title,index)=>(<div className="col fs-7 d-flex justify-content-center border" key={"uheader"+index}>{title}</div>))}
                    </div>
                    <div className="row">
                        {this.state.utilization.map((size,index)=>(<div className="col fs-7 d-flex justify-content-center border" key={"uinfo"+index}>{(size !== '-' ? size+'TB' : 'N/A')}</div>))}
                    </div>
                </div>
            </div>
        )
    }
}

export default RAID