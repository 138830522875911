import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "normalize.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/default.css"
import RAID from "./apps/raid";

function HomeScreen(){
  const icons = [
    {title: 'RAID', icon: 'hdd', path: "raid"},
    {title: 'SubNet', icon: 'hdd-network', path: "subnet"},
    {title: 'vCPU', icon: 'cpu', path: "vcpu"}
  ]
  return(
    <div className="container">
      <div className="row">
        {icons.map( ({title, icon, path},index) => (
          <Link to={path} key={"link"+index} className="col d-flex justify-content-center align-items-center flex-column p-3 cwhite text-decoration-none">
            <i className={"fs-1 bi bi-"+icon}></i>
            <span className="fw-bold">{title}</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

function App() {
  return (
    <Router>
      <div id="appWrapper" className="p-2">
        <header id="appHeader" className="d-flex align-items-center border-bottom border-2">
          <Link to="/" className="fw-bold cwhite text-decoration-none">
          <i className="bi bi-house me-2 ms-2"></i>
            TechTools
          </Link>
        </header>
        <div id="content" className="mt-3">
            <Switch>
              <Route path="/" component={HomeScreen} exact/>
              <Route path="/raid" component={RAID} />
            </Switch>
        </div>
        <footer id="appFooter" className="d-flex flex-column justify-content-center align-items-center border-top border-1">
          <span>Created By:</span>
          <span>Andrew Hochmuth</span>
        </footer>
      </div>
    </Router>
  );
}

export default App;
